import * as styles from "./SectionHeader.module.scss";

import React from "react";
import clsx from "clsx";

export default function SectionHeader({ children, className, anchor }) {
  return (
    <div className={clsx(className, styles.sectionHeader)}>
      <a id={anchor} href={`#${anchor}`}>
        {children}
      </a>
    </div>
  );
}
