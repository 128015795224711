import * as styles from "./Banner.module.scss";

import FullWidthImage from "./FullWidthImage";
import React from "react";
import logo from "../assets/images/lithic.svg";

export default function Banner({ title, subtitle, image }) {
  return (
    <div className={styles.banner}>
      <FullWidthImage image={image} className={styles.bannerImage} />
      <div className={styles.repositioner}>
        <div className={styles.bannerPositioner}>
          <div className={styles.bannerContainer}>
            <img className={styles.logo} alt={"Logo"} src={logo} />
            <div>
              <h1>{title}</h1>
              <p>{subtitle}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
