// extracted by mini-css-extract-plugin
export var about = "Landing-module--about--nsYW6";
export var card = "Landing-module--card--gGH9Y";
export var cardTitle = "Landing-module--card-title--HhwiU";
export var intro = "Landing-module--intro--1UVfS";
export var logo = "Landing-module--logo--JKwVm";
export var pageContent = "Landing-module--page-content--I5vch";
export var sectionSpacingB = "Landing-module--section-spacing-b--S1sWJ";
export var sectionSpacingT = "Landing-module--section-spacing-t--IP1Yp";
export var testimonial = "Landing-module--testimonial--+OO+c";
export var testimonialContainer = "Landing-module--testimonial-container--Rt23F";