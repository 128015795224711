import * as styles from "./Landing.module.scss";

import Banner from "./Banner";
import Card from "./Card";
import { Link } from "gatsby";
import React from "react";
import SectionHeader from "./SectionHeader";
import ampla from "../assets/logos/ampla.svg";
import boom from "../assets/logos/boom.png";
import clsx from "clsx";
import duckbillGroup from "../assets/logos/duckbill-group.svg";
import hearth from "../assets/logos/hearth.png";
import jenie from "../assets/logos/jenie.png";
import milkrun from "../assets/logos/milkrun.png";
import octoberSwimmer from "../assets/logos/october-swimmer.png";
import ombu from "../assets/logos/ombu.svg";
import rentroom from "../assets/logos/rentroom.svg";
import rideReport from "../assets/logos/ride-report.svg";
import suma from "../assets/logos/suma.png";

export default function Landing() {
  return (
    <div>
      <Banner title="Lithic Tech" subtitle="Build Apps That Rock" image="landing" />
      <Content className={styles.intro}>
        <p>
          <span className="emph">Building software is difficult</span>.
        </p>
        <p>
          If you&rsquo;re here, you already know that. You have a unique problem that
          can&rsquo;t be solved with off-the-shelf tools, or by run-of-the-mill
          consultants.
        </p>
        <p>
          Solving problems like this is why we started Lithic. It&rsquo;s why we left
          our cushy venture capital backed tech jobs &mdash; building software may be
          difficult, but it&rsquo;s <span className="emph">important</span>. The world
          would be a bit better if we solve the right problems with technology in the
          right way.
        </p>
        <p>
          We&rsquo;re a team of accomplished software engineers and problem solvers
          focused on building high-quality custom software, and we&rsquo;d{" "}
          <a href="#contact">love to learn about your business</a>.
        </p>
      </Content>
      <SectionHeader anchor="who-we-work-with">
        <h2>Who we work with</h2>
      </SectionHeader>
      <Content className={styles.intro}>
        <p>
          Choosing a development partner is a scary decision. You are having to pick
          between companies you know little about, and in many cases you are not an
          expert in the domain.
        </p>
        <p>
          Rest assured that starting a relationship with a client is also a big decision
          for us! We pick clients carefully and treat each one as an investment. We
          usually only take on long term engagements, so for us to succeed, you need to
          be <span className="emph">exceptionally happy</span>. We don&rsquo;t just
          build things that work okay.
        </p>
        <p>
          We build <span className="emph">apps that rock</span>.
        </p>
        <p>
          Lithic primarily works with three types of businesses. If you don&rsquo;t fit
          these criteria, we&rsquo;re still <a href="#contact">happy to talk</a>. If we
          can&rsquo;t help you, we probably know someone who is right for the job.
        </p>
      </Content>
      <Content>
        <Card className={styles.card}>
          <h3 className={styles.cardTitle}>Early Startups with Complex Tech</h3>
          <p>
            We know how hard it is to be a founder. It&rsquo;s especially difficult when
            you don&rsquo;t have the expertise to solve your technical problems
            yourself. Startups like this are our happy place.
          </p>
          <p>
            We&rsquo;ll work to make sure we all understand the problems you need
            solved, and then decide on a solution that fits your needs in terms of
            feature set, technology, and scalability. We&rsquo;ll also make sure
            we&rsquo;re working towards your long-term plans, depending on the type of
            business and team growth you expect.
          </p>
          <p>
            We think about what we build as a{" "}
            <span className="emph">Minimum Lovable Product</span>, rather than merely
            &ldquo;minimally viable.&rdquo; Our work should be delightful and do a
            stellar job running your business, whether you&rsquo;re looking for
            hockeystick growth or aiming for something steady and hands-off.
          </p>
          <p>
            Our special sauce comes down to our{" "}
            <Link to="/about">unique and effective methods</Link>. They are the key to
            getting things done with both speed and quality. This Shangri-La
            does&rsquo;t come from nowhere &mdash; it&rsquo;s been earned through years
            of training and discipline.
          </p>
        </Card>
      </Content>
      <Content>
        <Card className={styles.card}>
          <h3 className={styles.cardTitle}>Companies with Backend Programming Needs</h3>
          <p>
            We&rsquo;re skilled in all parts of the stack, but our real expertise and
            passion is around backend programming. Many of our clients are agencies or
            consultancies themselves, but they lack some deep technical expertise
            because it&rsquo;s outside of their core competency.
          </p>
          <p>
            We love working with clients like this, both because our expertise is a
            great fit, but also because we get to learn something new about your market
            and business.
          </p>
          <p>Some examples of what we&rsquo;ve built for clients like this are:</p>
          <ul className={styles.about}>
            <li>
              Machine Learning and OCR-based service for rapid classification of faxes.
            </li>
            <li>
              High-performance import pipeline of millions of rows of CSV data into a
              transaction database.
            </li>
            <li>
              Cloud cost analysis tooling capable of interactive projections over big
              data.
            </li>
            <li>Cutting edge content management system prototype development.</li>
            <li>Behavioral health intake and patient management software.</li>
            <li>
              Massively parallelizable and resilient data ingest of micromobility feeds.
            </li>
          </ul>
          <p>
            The reason we&rsquo;re able to execute whatever we take on is that our
            founding team has seen a lot in their career, and our{" "}
            <Link to="/about">
              methods haven proven to be the key for high-performing softare development
            </Link>
            . We apply this career of learning to your projects to make sure
            they&rsquo;re done right.
          </p>
        </Card>
      </Content>
      <Content>
        <Card className={styles.card}>
          <h3 className={styles.cardTitle}>
            DevOps, Golang, Ruby, Python, and Postgres Expertise
          </h3>
          <p>
            Finally, Lithic has expertise in certain technologies that make us a good
            fit for project or retainer-based setups where you need a team or individual
            who truly and deeply knows a technical domain.
          </p>
          <p>
            This may be building a new Continuous Integration and Deployment pipeline,
            debugging and removing performance bottlenecks, performing a general cleanup
            and overhaul of a codebase, consulting on application architecture, or
            really anything that leverages some of our deep technical expertise.
          </p>
          <p>
            Usually these engagements are shorter, but we take them on because they can
            have an enormous impact on your success. And at the end of the day,
            we&rsquo;re in this to build successful and happy businesses &mdash; both
            our own, and yours.
          </p>
        </Card>
      </Content>
      <SectionHeader anchor="how-we-bill">
        <h2>How We Bill</h2>
      </SectionHeader>
      <Content className={styles.intro}>
        <p>
          After &ldquo;is Lithic a good fit for my business?&rdquo;, the next question
          is often &ldquo;and how much do you cost?&rdquo; We aim to be transparent and
          direct in all things, so we have a page where you can{" "}
          <Link to="/pricing">read all about our fees and pricing model</Link>.
        </p>
      </Content>
      <SectionHeader anchor="clients">
        <h2>Our Clients</h2>
      </SectionHeader>
      <Content className={styles.intro}>
        <p>
          We&rsquo;re proud to count some fantastic companies in Portland Oregon and
          around the United States among our current and previous clients. We only take
          on a few clients at a time, so we can give them the focus and attention they
          deserve.
        </p>
        <div className={styles.testimonialContainer}>
          {testimonials.map((t) => (
            <Testimonial key={t.name} t={t} />
          ))}
        </div>
      </Content>
    </div>
  );
}

function Content({ children, className }) {
  return <div className={clsx(styles.pageContent, className)}>{children}</div>;
}

const testimonials = [
  { name: "Duckbill Group", href: "https://duckbillgroup.com", logo: duckbillGroup },
  { name: "MilkRun", href: "https://localmilkrun.com", logo: milkrun },
  { name: "Boom", href: "https://boompay.app", logo: boom, width: "13rem" },
  { name: "Ampla", href: "https://getampla.com", logo: ampla, width: "12rem" },
  { name: "Hearth Display", href: "https://hearthdisplay.com", logo: hearth },
  {
    name: "Suma",
    href: "https://mysuma.org",
    logo: suma,
    width: "13rem",
    align: "center",
  },
  {
    name: "Ride Report",
    href: "https://ridereport.com",
    logo: rideReport,
    align: "center",
  },
  { name: "October Swimmer", href: "https://octoberswimmer.com", logo: octoberSwimmer },
  {
    name: "OMBU",
    href: "https://ombuweb.com",
    logo: ombu,
    width: "14rem",
    align: "center",
  },
  {
    name: "JENIE",
    href: "https://jenie.com",
    logo: jenie,
    width: "11rem",
    align: "center",
  },
  {
    name: "RentRoom",
    href: "https://getrentroom.com",
    logo: rentroom,
    width: "13rem",
    align: "center",
  },
];
function Testimonial({ t }) {
  return (
    <div className={styles.testimonial} style={{ alignSelf: t.align }}>
      <a href={t.href}>
        <img
          src={t.logo}
          className={styles.logo}
          style={{ width: t.width }}
          alt={t.name}
        />
      </a>
    </div>
  );
}
