import * as styles from "./Card.module.scss";

import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

import { IoIosArrowDown } from "react-icons/io";
import clsx from "clsx";
import { useMeasure } from "react-use";

export default function Card({ children, className }) {
  const [showText, setShowText] = useState(false);
  const defaultHeight = "280px";

  const [contentHeight, setContentHeight] = useState(defaultHeight);
  const [ref, { height }] = useMeasure();

  const expand = useSpring({
    height: showText ? `${contentHeight}px` : defaultHeight,
    overflow: "hidden",
  });

  const spin = useSpring({
    transform: showText ? "rotate(180deg) scale(2)" : "rotate(0deg) scale(2)",
  });

  const toggleTransparency = useSpring({
    opacity: showText ? 0 : 1,
  });

  useEffect(() => {
    setContentHeight(`${height}px`);
  }, [height]);

  return (
    <div className={clsx(className, styles.container)}>
      <animated.button
        className={styles.expand}
        onClick={() => setShowText(!showText)}
        style={spin}
      >
        <IoIosArrowDown />
      </animated.button>
      <animated.div style={expand}>
        <div
          ref={ref}
          role="button"
          tabIndex={0}
          onClick={() => setShowText(!showText)}
          onKeyDown={() => setShowText(!showText)}
          className={styles.clickable}
        >
          {children}
          <br />
          <br />
        </div>
        <animated.div className={styles.containerOverlay} style={toggleTransparency} />
      </animated.div>
    </div>
  );
}
