import Helmet from "react-helmet";
import Landing from "../components/Landing";
import Layout from "../components/Layout";
import React from "react";
import SeoHelmet from "../components/SeoHelmet";

export default function Home() {
  return (
    <Layout>
      <SeoHelmet
        title=""
        meta={[
          {
            name: "keywords",
            content:
              "web development, software, website, web app, monolith, microservices, ruby, rails, python, django, go, golang",
          },
        ]}
      />
      <Helmet>
        <title>Lithic Tech | Software Development & Consulting</title>
      </Helmet>
      <Landing />
    </Layout>
  );
}
